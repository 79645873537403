import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { change } from './features/counterGrades';
import React from 'react';
import Item from './Item';

function Category(props) {
  return (
    <div>
      <h4>{props.category.name}</h4>
      <ul>
        {props.category.items.map(item => (<Item item={item}/>))}
      </ul>
    </div>
  );
}

export default Category;
