import { createSlice } from '@reduxjs/toolkit'
import data from './../categories.json'

export const grades = createSlice({
  name: 'counter',
  initialState: {
    categories: data['categories'],
    grades: []
  },
  reducers: {
    change: (state, action) => {
      if (state.grades.includes(action.payload)) {
        state.grades = state.grades.filter(function(item) {
          return item !== action.payload
      });
      } else {
        state.grades.push(action.payload);
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { change } = grades.actions

export default grades.reducer