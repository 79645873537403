import './App.css';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Category from './Category';

function App() {

  const categories = useSelector((state) => state.counter.categories)
  const grades = useSelector((state) => state.counter.grades)
  const dispatch = useDispatch()

  const findValue = (grades) => {
    let values = '';
    categories.map(category => {
      category.items.map(item => {
        if (grades.includes(item.id)) {
          values += item.value + ' '
        }
      })
    })

    return values;
  }

  return (
    <Container>
      <Row>
        <Col>
          {categories.map(category => (<Category key={category.id} category={category}></Category>))}
        </Col>
        <Col>
          <textarea style={{width: "90%", height:"400px"}} value={findValue(grades)}/>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
