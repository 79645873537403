import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { change } from './features/counterGrades';
import React from 'react';

function Item(props) {
  
  const dispatch = useDispatch()
  
  const [color, changeColor] = React.useState("black");

  const changeState = () => {
    if (color == "black") {
      changeColor("red")
    } else {
      changeColor("black")
    }
  }

  return (
      <li style={{ color: color, cursor:'pointer'}} key={props.item.id} onClick={() => {dispatch(change(props.item.id)); changeState()}}>{props.item.value}</li>
  );
}

export default Item;
